<template>
    <div class="flex dark:bg-gray-800 bg-white h-screen w-full justify-center items-center">
        <h1 class="text-9xl text-center text-gray-800 dark:text-white">404 Not Found</h1>
    </div>
</template>

<script>
export default {
    meta: {
        title: '404'
    },
    name: "NotFound"
}
</script>

<style scoped>

</style>